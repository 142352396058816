import {
  MyNintendoGoldCoinIcon,
  Text,
  Skeleton,
} from '@nintendo-of-america/component-library';
import { useLocalizer } from '@nintendo-of-america/react-hooks';
import s from './GoldPoints.module.css';

const GoldPoints = ({ eligiblePoints, loading }) => {
  const { text } = useLocalizer();

  if (loading) {
    return <Skeleton className={s.skeleton} width="100%" />;
  }

  return (
    <div className={s.eligiblePoints}>
      <MyNintendoGoldCoinIcon size={24} />
      <Text variant="legal">
        {text('Eligible for up to {0} Gold Points', {
          args: [
            <span className={s.boldText} key="goldPoints">
              {eligiblePoints}
            </span>,
          ],
        })}
      </Text>
    </div>
  );
};

export default GoldPoints;
