import { FILTERABLE_ATTRIBUTES } from '@nintendo-of-america/search';
import { getProductTags } from '@shared/util';

export const SEARCH_PREFIX = '/search';

const playerRange = (label, min, max, options) => {
  const { text } = options;
  const players = parseInt(max || min);

  const filterLabel =
    players === 1 ? text('Single Player') : players > 3 ? '4+' : `${players}+`;

  return {
    href: `${SEARCH_PREFIX}#cat=gme&f=playerCount&playerCount=${encodeURIComponent(
      filterLabel
    )}`,
    label:
      label + ' (' + (min != max ? min + '-' : '') + (max ? max : '') + ')',
  };
};

/**
 * @typedef {Object} PlayerRangeInfo
 * @property {String} label Display label for the player range
 * @property {String} href Link to the landing page for the player range
 */

/**
 * Returns an array of labels and hrefs to denote the supported
 * player ranges for the given product (i.e. "Single System (1 - 2)", "Online", etc..)
 * @param {Object} product The product used to generate the player ranges from.
 * @param {Function} text Method used to localize the player range labels
 * @returns {PlayerRangeInfo[]} An array of PlayerRangeInfo objects for the product
 */
export const getPlayerSupport = (product, text) => {
  let result = [];
  if (product.playersMax) {
    result.push(
      playerRange(
        text('Single System'),
        product.playersMin,
        product.playersMax,
        { text }
      )
    );
  }
  if (product.playersMaxLocal) {
    result.push(
      playerRange(
        text('Local wireless'),
        product.playersMinLocal,
        product.playersMaxLocal,
        { text }
      )
    );
  }
  if (product.playersMaxOnline) {
    result.push(
      playerRange(
        text('Online'),
        product.playersMinOnline,
        product.playersMaxOnline,
        { text }
      )
    );
  }
  return result;
};

const generateSearchUrl = (filterAttribute, label) => {
  return `${SEARCH_PREFIX}/#cat=gme&f=${filterAttribute}&${filterAttribute}=${encodeURIComponent(
    label
  )}`;
};

/**
 * @typedef {Object} TagInfo
 * @property {String} label Display label for the tag
 * @property {String} url Link to the landing page for the tag
 */

/**
 * Returns the combined set of product tags with pre-generated urls
 * that link to the appropriate landing page.
 * @param {Object} product The product used to pull tags from
 * @returns {TagInfo[]} An array of TagInfo objects for the product
 */
export const getProductTagInfo = (product) => {
  const { gameGenres, gameFeatures, waysToPlay } = getProductTags(product);
  const gameGenresTagInfo = gameGenres.map(({ label }) => ({
    url: generateSearchUrl(FILTERABLE_ATTRIBUTES.GAME_GENRES, label),
    label,
  }));
  const gameFeaturesTagInfo = gameFeatures.map(({ label }) => ({
    url: generateSearchUrl(FILTERABLE_ATTRIBUTES.GAME_FEATURES, label),
    label,
  }));
  const waysToPlayTagInfo = waysToPlay.map(({ label }) => ({
    url: generateSearchUrl(FILTERABLE_ATTRIBUTES.WAYS_TO_PLAY, label),
    label,
  }));
  return [...gameGenresTagInfo, ...gameFeaturesTagInfo, ...waysToPlayTagInfo];
};
