import React, { useMemo } from 'react';
import {
  BarcodeIcon,
  BuildingIcon,
  CalendarIcon,
  DPadIcon,
  GearIcon,
  GlobeIcon,
  GroupIcon,
  StorageIcon,
  SwitchLiteIcon,
  SkuIcon,
  Heading,
  Link,
  ScreenReaderOnly,
  WrenchIcon,
  TagList,
} from '@nintendo-of-america/component-library';
import { useLocalizer, useFeature } from '@nintendo-of-america/react-hooks';
import {
  TOP_LEVEL_CATEGORY_CODE,
  FILTERABLE_ATTRIBUTES,
} from '@nintendo-of-america/search';
import { Grid } from '@shared/ui';
import { getHighestContentRatingCode, getProductTags } from '@shared/util';
import useMediaWidth, { MEDIA_TYPE } from '@local/lib/hooks/useMediaWidth';
import { fileSize, isProductDigital } from '@local/lib/helpers';
import { SEARCH_PREFIX, getPlayerSupport, getProductTagInfo } from './utils';
import * as S from './ProductInfo.styles';

const InfoRow = ({ icon, heading, descriptions }) => {
  return (
    <S.InfoRow>
      {icon}
      <Heading variant="h3">{heading}</Heading>
      <S.InfoDescr>
        {descriptions.map((descr) => (
          <div key={descr.label}>
            {descr.href ? (
              <Link href={descr.href}>{descr.label}</Link>
            ) : (
              descr.label
            )}
          </div>
        ))}
      </S.InfoDescr>
    </S.InfoRow>
  );
};

const ProductInfo = ({ product }) => {
  const { date, DateFormat, text } = useLocalizer();

  const isDesktop = useMediaWidth(MEDIA_TYPE.DESKTOP);

  const enableTagLists = useFeature('WDEV-4622-tags');
  const enableTagsOnPDP =
    !!enableTagLists?.variant?.payload?.value?.enableOnPDP;
  const enableTagsOnPLP =
    !!enableTagLists?.variant?.payload?.value?.enableOnPLP;

  const productTagInfo = useMemo(() => getProductTagInfo(product), [product]);
  const productTags = useMemo(() => getProductTags(product), [product]);

  const genres = product.genres?.map((genre) => ({
    href: `${SEARCH_PREFIX}/#cat=gme&f=genres&genres=${encodeURIComponent(
      genre.label
    )}`,
    label: genre.label,
  }));

  const gameGenres = productTags.gameGenres?.map((genre) => ({
    href: `${SEARCH_PREFIX}/#cat=gme&f=gameGenreLabels&gameGenreLabels=${encodeURIComponent(
      genre.label
    )}`,
    label: genre.label,
  }));

  const playModes = product.playModes?.map((playMode) => text(playMode.label));
  const playerSupport = getPlayerSupport(product, text);
  const isDigital = isProductDigital(product);

  const isHardware =
    product?.topLevelCategory?.code === TOP_LEVEL_CATEGORY_CODE.HARDWARE;

  return (
    <>
      {enableTagsOnPDP && productTagInfo.length > 0 && (
        <S.TagContainer>
          <TagList
            sectionTitle={text('Related tags')}
            tags={productTagInfo}
            showMoreLabel={text('Show more')}
            showLessLabel={text('Show less')}
            defaultRowCount={isDesktop ? 2 : 3}
          />
        </S.TagContainer>
      )}
      <S.InfoSection>
        <ScreenReaderOnly id="product-info">
          <Heading>{text('Product information')}</Heading>
        </ScreenReaderOnly>
        <Heading.NewLevel>
          <Grid columns={1} gap={0}>
            {product.releaseDate && (
              <InfoRow
                icon={<CalendarIcon size={28} />}
                heading={text('Release date')}
                descriptions={[
                  {
                    label: product.releaseDateDisplay
                      ? product.releaseDateDisplay
                      : date(product.releaseDate, { format: DateFormat.LONG }),
                  },
                ]}
              />
            )}
            {product.playersMax && (
              <InfoRow
                icon={<GroupIcon size={28} />}
                heading={text('No. of players')}
                descriptions={playerSupport}
              />
            )}
            {enableTagsOnPLP && product.gameGenres && (
              <InfoRow
                icon={<DPadIcon size={28} />}
                heading={text('Genre')}
                descriptions={gameGenres}
              />
            )}
            {!enableTagsOnPLP && product.genres && (
              <InfoRow
                icon={<DPadIcon size={28} />}
                heading={text('Genre')}
                descriptions={genres}
              />
            )}
            {product.softwarePublisher && (
              <InfoRow
                icon={<BuildingIcon size={28} />}
                heading={text('Publisher')}
                descriptions={[
                  {
                    href: `${SEARCH_PREFIX}#cat=gme&f=softwarePublisher&softwarePublisher=${encodeURIComponent(
                      product.softwarePublisher
                    )}`,
                    label: product.softwarePublisher,
                  },
                ]}
              />
            )}
            {product.softwareDeveloper && (
              <InfoRow
                icon={<WrenchIcon size={28} />}
                heading={text('Developer')}
                descriptions={[
                  {
                    href: `${SEARCH_PREFIX}#cat=gme&f=softwareDeveloper&softwareDeveloper=${encodeURIComponent(
                      product.softwareDeveloper
                    )}`,
                    label: product.softwareDeveloper,
                  },
                ]}
              />
            )}
            {product.contentRating != null && (
              <InfoRow
                icon={<GearIcon size={28} />}
                heading={text('ESRB rating')}
                descriptions={[
                  {
                    href: `${SEARCH_PREFIX}#cat=gme&sort=df&f=${FILTERABLE_ATTRIBUTES.CONTENT_RATING}&${FILTERABLE_ATTRIBUTES.CONTENT_RATING}=${encodeURIComponent(
                      getHighestContentRatingCode(product)
                    )}`,
                    label: product.contentRating.label,
                  },
                ]}
              />
            )}
            {product.playModes && (
              <InfoRow
                icon={<SwitchLiteIcon size={28} />}
                heading={text('Supported play modes')}
                descriptions={[{ label: playModes.join(', ') }]}
              />
            )}
            {product.romFileSize && (
              <InfoRow
                icon={<StorageIcon size={28} />}
                heading={text('Game file size')}
                descriptions={[{ label: fileSize(product.romFileSize) }]}
              />
            )}
            {product.supportedLanguages && (
              <InfoRow
                icon={<GlobeIcon size={28} />}
                heading={text('Supported languages')}
                descriptions={[
                  {
                    label: product.supportedLanguages?.map(text).join(', '),
                  },
                ]}
              />
            )}
            {product.countryOfOrigin && (
              <InfoRow
                icon={<GlobeIcon size={28} />}
                heading={text('Country of origin')}
                descriptions={[{ label: product.countryOfOrigin }]}
              />
            )}
            {product.manufacturer && (
              <InfoRow
                icon={<BuildingIcon size={28} />}
                heading={text('Manufacturer')}
                descriptions={[
                  {
                    href: `${SEARCH_PREFIX}#cat=${isHardware ? 'hdw' : 'merch'}&f=manufacturer&manufacturer=${encodeURIComponent(
                      product.manufacturer
                    )}&p=1&sort=df`,
                    label: product.manufacturer,
                  },
                ]}
              />
            )}
            {product.sku && !isDigital && (
              <InfoRow
                icon={<SkuIcon size={28} />}
                heading={text('SKU')}
                descriptions={[{ label: product.sku }]}
              />
            )}
            {product.upc && !product.nsuid && (
              <InfoRow
                icon={<BarcodeIcon size={28} />}
                heading={text('UPC')}
                descriptions={[{ label: product.upc }]}
              />
            )}
          </Grid>
        </Heading.NewLevel>
      </S.InfoSection>
    </>
  );
};

export default ProductInfo;
